.user-avatar {
  width: 60px;
  height: 60px;
  @include border-radius(100%);
  img {
    width: 100%;
    height: 100%;
    @include border-radius(100%);
    object-fit: cover;
  }
}
/*===== user-module-list =====*/
.user-module-list {
  border-top: 1px solid rgba(128, 137, 150, 0.1);
  border-bottom: 1px solid rgba(128, 137, 150, 0.1);
  li {
    font-size: $font-14;
    padding: 6px 15px;
    &:not(:last-child) {
      border-right: 1px solid rgba(128, 137, 150, 0.1);
    }
    span {
      display: block;
      color: $theme-color;
      margin-bottom: 2px;
    }
  }
}