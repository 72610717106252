.height-100vh {
  height: 100vh;
}
.height-500 {
  height: 500px;
}
.height-300 {
  height: 300px;
}
/*==== zoom-control ====*/
.zoom-control {
  @include border-radius(6px);
  @include box-shadow(0 0 3px rgba(0,0,0, 0.3));
  background-color: $white;
  div {
    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-bottom: 0;
    }
  }
}
.zoom-in,
.zoom-out {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: $white;
  border-bottom: 1px solid $gray;
  text-align: center;
  color: $theme-color;
  cursor: pointer;
  font-size: $font-16;
  &:hover {
    background-color: $gray;
  }
}
/*======= popup-box ========*/
.popup-box {
  background-color: $white;
  @include border-radius(6px);
  position: relative;
  @include box-shadow(0 1px 10px rgba(0,0,0, 0.2));
  &:after {
    position: absolute;
    content: "";
    bottom: -12px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid $white;
    @include transform(translateX(-50%));
  }
}
/*===== close-button =====*/
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  @include border-radius(100%);
  background-color: $white;
  text-align: center;
  line-height: 25px;
  color: $theme-color;
  font-size: $font-15;
  cursor: pointer;
  @include box-shadow(0 1px 10px rgba(0,0,0, 0.1));
  &:hover {
    color: $theme-color-2;
  }
}
/*===== popup-box-img-container =====*/
.popup-box-img-container {
  img {
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}
/*===== popup-box-content =====*/
.popup-box-content {
  padding: 15px;
  h3 {
    font-size: $font-20;
    margin-bottom: 3px;
    font-weight: $font-weight-semi-bold;
    a {
      color: $theme-color;
      @include transition(0.2s);
      &:hover {
        color: $theme-color-2;
      }
    }
  }
  p {
    font-size: $font-15;
    margin-bottom: 3px;
  }
  .star-rating {
    font-size: $font-14;
  }
}
/*===== popup-box-status =====*/
.popup-box-status {
  font-size: $font-14;
  margin-top: 8px;
}

/* star rating */
.star-rating {
  line-height: 18px;
  .star {
    display: inline-block;
    padding: 0;
    float: left;
    margin-right: 4px;
    position: relative;
    &.half::after,
    &::before {
      font-family: "FontAwesome", sans-serif;
      content: "\f005";
      display: block;
      color: $color-5;
    }
    &.half {
      &::before {
        color: $gray-2;
      }
    }
    &.half {
      &::after {
        color: $color-5;
        position: absolute;
        top: 0;
        width: 50%;
        display: block;
        height: 100%;
        overflow: hidden;
      }
    }
    &.empty {
      &::before {
        color: $gray-2;
      }
    }
  }
}
/* rating-counter */
.rating-counter {
  color: $theme-color-4;
  padding-left: 5px;
  display: inline-block;
  font-size: $font-14;
  position: relative;
  top: -1px;
}

/*== cluster ===*/
.cluster{
  div {
    height: 31px;
    background: $theme-color;
    color: $white;
    font-weight: $font-weight-semi-bold;
    @include border-radius(100%);
    @include box-shadow(0 0 0 4px rgba(0,0,0,0.5));
    border: 2px solid  $theme-color;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    animation: cluster-animation 1.5s infinite;
    &:hover {
      background-color: $theme-color-4;
    }
  }
}