.gallery-carousel {
  position: relative;
  .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    @include transform(translateY(-50%));
    left: 0;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.gallery-item {
  img {
    @include border-radius(4px);
  }
}