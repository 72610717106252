$theme-color: #333f57;
$theme-color-2: #ff6b6b;
$theme-color-3: #ea5555;
$theme-color-4: #808996;
$theme-color-5: #40CC6F;
$theme-font:  'Work Sans', sans-serif;
$theme-font-2: 'Mukta', sans-serif;
/* font size */
$font-140: 140px;
$font-100: 100px;
$font-60: 60px;
$font-45: 45px;
$font-40: 40px;
$font-36: 36px;
$font-32: 32px;
$font-30: 30px;
$font-28: 28px;
$font-26: 26px;
$font-24: 24px;
$font-22: 22px;
$font-20: 20px;
$font-21: 21px;
$font-18: 18px;
$font-16: 16px;
$font-15: 15px;
$font-14: 14px;
$font-13: 13px;
$font-12: 12px;
$font-11: 11px;
/* font weight */
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;
/* color */
$white: #fff;
$light-slim: #F5F7FC;
$black: #000;
$gray: #eee;
$gray-2: #ddd;
$gray-3: #d2d0d0;
$color-3: #8C43FF;
$color-4: #28d5a7;
$color-5: #f9b851;
$color-6: #CC08E9;
$color-7: #FF8C2A;
$color-8: #9cce09;
$color-9: #307CF3;
$alter-color: #3b5998;
$alter-color-2: #1da1f2;
$alter-color-3: #e1306c;
$alter-color-4: #2877B5;
$alter-color-5: #f00125;
/* breakpoint */
$laptop_l: '(max-width: 1440px)';
$laptop_m: '(max-width: 1366px)';
$laptop_m_four: '(max-width: 1199px)';
$laptop_m_five: '(max-width: 1024px)';
$tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
$large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
$small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';

$device-575: '(max-width: 575px)';
