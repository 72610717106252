/*======= file upload ========*/
.file-upload-wrap {
  position: relative;
  .file-upload-input {
    @include border-radius(4px);
    width: 100%;
    border: 2px dashed rgba(128, 137, 150, 0.2);
    height: 170px;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    @include transition(0.3s);
    text-indent: -99999px;
    &:hover {
      border-color: rgba(128, 137, 150, 0.5);
      background-color: rgba(128, 137, 150, 0.04);
    }
  }
  .file-upload-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    line-height: 170px;
    text-align: center;
    font-size: $font-18;
    color: rgba(128, 137, 150, 0.9);
    font-weight: $font-weight-medium;
  }
  .MultiFile-list {
    margin-top: 20px;
    > .MultiFile-label {
      @include border-radius(4px);
      background-color: rgba(128, 137, 150, 0.06);
      display: inline-block;
      border: 2px solid rgba(128, 137, 150, 0.1);
      padding: 16px;
      position: relative;
      width: 100%;
      + .MultiFile-label {
        margin-top: 20px;
      }
      > span {
        display: flex;
        flex-wrap: wrap;
        //justify-content: center;
      }
    }
    span {
      &.MultiFile-label {
        box-shadow: 0 0.2rem 0.7rem 0 rgba(0,0,0, .08);
        position: relative;
        text-align: center;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        margin: 10px;
        width: 30%;
      }
    }
    .MultiFile-remove {
      @include box-shadow(0 1px 5px rgba(0, 0, 0, 0.1));
      @include border-radius(100%);
      position: absolute;
      background-color: $white;
      color: $theme-color;
      width: 30px;
      height: 30px;
      text-align: center;
      top: -16px;
      right: -16px;
      z-index: 1;
      &:hover {
        color: $theme-color-2;
      }
    }
    .MultiFile-title {
      background-color: rgba(0,0,0, .4);
      color: $white;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .MultiFile-preview {
      max-width: 100% !important;
      max-height: 100% !important;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      height: 100px;
      object-fit: cover;
    }
  }
}
/*======== file-upload-wrap-layout-2 =======*/
.file-upload-wrap-layout-2 {
  .file-upload-input {
    width: 170px;
    border: 1px solid rgba(128, 137, 150, 0.1);
    height: 50px;
    background-color: rgba(128, 137, 150, 0.04);
  }
  .file-upload-text {
    line-height: 50px;
    font-size: $font-16;
    width: auto;
    padding-left: 20px;
  }
}
/*======== file-upload-wrap-3 =======*/
.file-upload-wrap-3 {
  .file-upload-input {
    width: 30px;
    border: 0;
    height: 25px;
    background-color: transparent;
    @include border-radius(0);
    &:hover {
      background-color: transparent;
    }
  }
  .file-upload-text {
    line-height: 27px;
    font-size: $font-18;
  }
  .MultiFile-list {
    position: absolute;
    right: 0;
    width: 1000px;
    bottom: 55px;
    @media #{$tab_device} {
      width: 310px;
    }
    @media #{$large_mobile} {
      width: 310px;
    }
    @media #{$small_mobile} {
      width: 310px;
    }
    > .MultiFile-label {
      background-color: $white;
      @include box-shadow(0 10px 40px rgba(82, 85, 90, 0.1));
    }
  }
}
/*======== file-upload-wrap-3 =======*/
.file-upload-wrap-4 {
  .file-upload-input {
    height: 100px;
  }
  .file-upload-text {
    line-height: 100px;
    font-size: $font-16;
  }
}
/*====== edit-profile-photo =======*/
.edit-profile-photo {
  .file-upload-wrap {
    .MultiFile-list {
      .MultiFile-preview {
        max-width: 10rem !important;
        max-height: 10rem !important;
      }
    }
  }
}