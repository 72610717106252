.random-img {
  width: 80px;
  height: 80px;
  @include border-radius(100%);
  position: absolute;
  top: 0;
  left: 0;
  @include box-shadow(0 10px 30px rgba(255, 77, 121, 0.1));
  @media #{$tab_device} {
    display: none;
  }
  @media #{$large_mobile} {
    display: none;
  }
  @media #{$small_mobile} {
    display: none;
  }
  &:nth-child(1) {
    top: 4%;
    left: 2%;
  }
  &:nth-child(2) {
    top: 47%;
    left: 6%;
  }
  &:nth-child(3) {
    top: auto;
    bottom: 10%;
    left: 20%;
  }
  &:nth-child(4) {
    top: -10px;
    left: auto;
    right: -20px;
  }
  &:nth-child(5) {
    top: 33%;
    left: auto;
    right: 20%;
  }
  &:nth-child(6) {
    top: auto;
    left: auto;
    right: 5%;
    bottom: 15%;
  }
}
/*========= testimonial-item =========*/
.testimonial-item {
  .testi__img {
    width: 90px!important;
    height: 90px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    @include border-radius(50%);
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.2));
    img {
      width: 100%;
      height: 100%;
      @include border-radius(50%);
    }
  }
  .testi-comment {
    background-color: $white;
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    @include border-radius(6px);
    position: relative;
    padding: 30px;
    margin-bottom: 42px;
    .testi__desc {
      font-size: $font-18;
      line-height: 30px;
    }
    &:before {
      position: absolute;
      content: "";
      bottom: -10px;
      left: 50%;
      @include transform(translateX(-50%) rotate(45deg));
      width: 18px;
      height: 18px;
      background-color: $white;
    }
  }
  .tesi__title {
    font-size: $font-18;
    font-weight: $font-weight-semi-bold;
    margin-top: 17px;
    margin-bottom: 15px;
  }
  .testi__meta {
    font-size: $font-14;
    text-transform: capitalize;
    padding: 6px 17px;
    border: 1px solid $gray;
    @include border-radius(30px);
  }
}