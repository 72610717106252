/*===== my-tabs ====*/
.my-tabs {
  border-bottom: 0;
  .nav-item {
    margin-bottom: 0;
    margin-right: 6px;
  }
  .nav-link {
    @include border-radius(30px);
    background-color: rgba(255, 255, 255, 0.2);
    color: $white;
    border: 0;
    @include transition(0.2s);
    &:hover,
    &.active {
      color: $theme-color-2;
      background-color: $white;
    }
  }
}
/*===== my-tabs-2 ====*/
.my-tabs-2 {
  .nav-link {
    background-color: $gray;
    color: $theme-color;
    @include border-radius(4px);
    &:hover,
    &.active {
      color: $white;
      background-color: $theme-color-2;
    }
  }
}